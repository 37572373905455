import { createApp } from 'vue'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import { VueRecaptchaPlugin } from 'vue-recaptcha/head'
import App from './App.vue'
import i18n from './i18n.js'
import safeHtml from './plugins/safe-html.js'
import router from './router.js'
import './datadog.js'
import './validation'

const app = createApp(App)

app.use(autoAnimatePlugin)
app.use(i18n)
app.use(router)
app.use(safeHtml)
if (import.meta.env.VITE_APP_RECAPTCHA_V2_SITE_KEY) {
  app.use(VueRecaptchaPlugin, {
    loaderOptions: {
      params: {
        render: import.meta.env.VITE_APP_RECAPTCHA_V2_SITE_KEY,
      },
    },
    v2SiteKey: import.meta.env.VITE_APP_RECAPTCHA_V2_SITE_KEY,
  })
}

app.mount('#app')
