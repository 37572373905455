<template>
  <div :class="{ background: true, 'background-new-recommendation': isOnNewRecommendation }">
    <header>
      <Row class="header-content">
        <Logo />
        <!-- TODO: Add @click="handleLogoClick" when exit intent popup is ready to go live -->
        <FlexSpace />
        <AppearanceSwitch />
        <LanguageSelector :label="$t('Language')" />
        <LoginButton v-if="route.name !== 'up-next'" />
      </Row>
    </header>

    <main :class="{ 'new-recommendation': isOnNewRecommendation }">
      <RouterView />
      <!-- <ExitIntentPopup :show-modal="showModal" @close-popup="setShowModal(false)" /> -->
    </main>
  </div>
</template>

<script setup>
// import { computed, ref, onBeforeUnmount, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { AppearanceSwitch, FlexSpace, Row } from '@justworkshr/alma'
// import { useSessionStorage } from '@vueuse/core'
// import ExitIntentPopup from '@/components/ExitIntentPopup.vue'
import LanguageSelector from '@/components/LanguageSelector.vue'
import LoginButton from '@/components/LoginButton.vue'
import Logo from '@/components/Logo.vue'

const route = useRoute()
const isOnNewRecommendation = route.path.endsWith('/recommendation-1')

// TODO: Uncomment exit intent popup code when it's ready to go live

// const showModal = ref(false)
// const hasShownPopup = useSessionStorage('exit-intent-shown', false)

// const shouldShowPopup = computed(() => !hasShownPopup.value)

// function setShowModal(value) {
//   showModal.value = value
// }

// Touch devices
// function handleLogoClick(event) {
//   if (!hasShownPopup.value && window.matchMedia('(pointer: coarse)').matches) {
//     event.preventDefault()
//     setShowModal(true)
//     hasShownPopup.value = true
//   }
// }

// Desktop
// function handleMouseMove(event) {
//   const { clientY } = event
//   const nearTop = clientY < 10
//   if (nearTop && shouldShowPopup.value) {
//     setShowModal(true)
//     hasShownPopup.value = true
//   }
// }

// onMounted(() => {
//   if (window.matchMedia('(pointer: fine)').matches) {
//     setTimeout(() => {
//       addEventListener('mousemove', handleMouseMove)
//     }, 5000)
//   }
// })

// onBeforeUnmount(() => {
//   removeEventListener('mousemove', handleMouseMove)
// })
</script>

<style scoped>
.background {
  background-color: light-dark(#fffaf3, var(--color-navy));

  &.background-new-recommendation {
    background: light-dark(var(--color-gray-200), var(--color-page-background));
  }
}

header {
  background-color: var(--color-page-background);
}

.header-content,
main {
  width: 1280px;
  max-width: 100vw;
  margin: auto;
}

.header-content {
  padding-inline: var(--space-md);
  padding-block: var(--space-md);
  @media (width < 375px) {
    gap: 0;
  }
  @media (width >= 576px) {
    padding-inline: var(--space-2xl);
  }
}

main {
  min-height: 100vh;
  padding: var(--space-md);
  position: relative;
  @media (width >= 576px) {
    width: auto;
    padding: var(--space-2xl);
  }

  &.new-recommendation {
    padding: var(--space-sm) var(--space-md);

    @media (width >= 576px) {
      padding: var(--space-sm) var(--space-2xl);
    }
  }
}
</style>
